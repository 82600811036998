<template>
  <v-row class="px-2">
    <v-col class="d-flex flex-column">
      <SectionTitle title="Composição" />

      <v-card
        class="u-radius-20 d-flex flex-column grey lighten-3"
        min-height="400px"
        min-width="85%"
      >
        <div class="flex-grow-1">
          <div
            v-if="product.product_composition.items.length < 1"
            class="text-center"
          >
            <img
              class="mt-5"
              src="@/assets/empty-composition.svg"
              alt="Nenhum item atribuído"
              width="250px"
            />
            <h3 class="text-h5 grey--text text-lighten-3">
              Adicione itens à sua <br />
              composição
            </h3>
          </div>

          <CompositionList
            v-else
            :items="product.product_composition.items"
            height="364px"
            @clickedItem="toItems"
          />
        </div>
        <v-footer
          rounded
          class="primary py-1 d-flex justify-center"
        >
          <h4 class="white--text text-h6">
            Custo: R$ {{ parseFloat(cost.toFixed(2)) }}
          </h4>
        </v-footer>
      </v-card>
    </v-col>

    <v-col class="d-flex flex-column">
      <SectionTitle title="Itens" />

      <v-card
        class="u-radius-20 d-flex flex-column grey lighten-3"
        min-height="400px"
        min-width="85%"
      >
        <div class="flex-grow-1">
          <CompositionList
            :items="compositionItems"
            height="400px"
            @clickedItem="toComposition"
          />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    CompositionList: () => import('./CompositionList'),
  },
  data: () => ({
    isEmpty: true,
    benched: 0,
    compositionSelected: [],
    compositionItems: [],
  }),
  computed: {
    cost: function () {
      return this.product.product_composition.total_cost
    },
  },
  methods: {
    getComposition() {
      this.$api
        .get('product_compositions')
        .then((res) => {
          this.compositionItems = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.compositionItems.isLoading = false
        })
    },
    toComposition(item) {
      let idx = this.compositionItems.indexOf(item)

      this.product.product_composition.items.push(item)
      this.compositionItems.splice(idx, 1)

      this.product.product_composition.total_cost =
        parseFloat(this.product.product_composition.total_cost) +
        parseFloat(item.price)
    },
    toItems(item) {
      let idx = this.product.product_composition.items.indexOf(item)

      this.compositionItems.push(item)
      this.product.product_composition.items.splice(idx, 1)
      this.product.product_composition.total_cost =
        parseFloat(this.product.product_composition.total_cost) -
        parseFloat(item.price)
    },
  },
  created() {
    this.getComposition()
  },
}
</script>

<style lang="scss" scoped>
.u-radius-20 {
  border-radius: 20px;
  overflow: hidden;
}
</style>
